import PropTypes from 'prop-types';
import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './index.module.scss';
import { Link, Text, Image } from '@/components/atoms';
import { ProductSheetCard, GeneralModal } from '@/components/molecules';
import apiProducts from '@/api/products';
import createUUID from '@/utils/createUUID';
import { getCookies } from '@/services/cookies';
import { getProductData } from '@/utils/products';
import { setVariant } from '@/redux/productDetail/productDetailReducer';
import FavoriteButton from '@/components/organisms/FavoriteButton';
import useDeviceType from '@/hooks/useDeviceType';

const ProductImageWrapper = (props) => {
  const { style, productUrl, openModalOnClick, children } = props;
  const dispatch = useDispatch();
  const storeId = useSelector((state) => state.store);
  const eventId = createUUID();
  const [showModal, setShowModal] = useState(false);
  const [product, setProduct] = useState({
    images: [{ zoom: { url: '' }, thumbnail: { url: '' } }]
  });

  const handleSetProduct = (newValues) => {
    setProduct({ ...product, ...newValues });
  };
  const { isDesktop } = useDeviceType();
  const cardClass = cn({
    [styles.productGalleryCardDesktop]: true
  });
  const getIdProduct = (slug = '') => {
    const arrSlug = slug.split('-');
    return arrSlug.slice(arrSlug.length - 2);
  };
  const [id, idColor] = getIdProduct(productUrl);
  const fetchProduct = async () => {
    const { token } = getCookies();
    const res = await apiProducts.getByID(id, eventId, token, storeId);
    if (res.success) {
      const colorSelected = res.data?.colors[0];

      setProduct(
        getProductData(res.data, productUrl, id, colorSelected, eventId)
      );
    }
  };
  useEffect(() => {
    if (showModal) fetchProduct();
  }, [showModal]);

  const handleOpenModal = (e) =>
    !e.target.closest('#favorite-btn') && setShowModal(true);

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleClickLinkItem = () => {
    document.cookie = `idColor=${
      product.idColor || idColor
    }; max-age=${7200}; path=/`;
  };

  return openModalOnClick ? (
    <li>
      <div
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleOpenModal();
          }
        }}
        role="button"
        onClick={handleOpenModal}
        className={`${styles.btn} ${cardClass}`}
      >
        {children}
      </div>
      {showModal && product.name && (
        <GeneralModal
          closeColor={
            isDesktop ? 'var(--color-white-absolute)' : 'var(--color-grey-dark)'
          }
          onClose={() => {
            dispatch(setVariant());
            handleCloseModal();
          }}
          withoutCloseIcon
        >
          <ProductSheetCard
            linkToDetail
            isModal
            handleSetProduct={handleSetProduct}
            product={product}
            closeModal={handleCloseModal}
          />
        </GeneralModal>
      )}
    </li>
  ) : (
    <Link
      handleClick={handleClickLinkItem}
      onContextMenu={handleClickLinkItem}
      href={`/${productUrl.replace(
        new RegExp(`-${product.idColor || idColor}`),
        ''
      )}`}
      linkStyle={style}
      className={cardClass}
      shouldRedirect={(e) => e.target.closest('#favorite-btn')}
    >
      {children}
    </Link>
  );
};

const ProductGalleryCard = (props) => {
  const { imageSrc, cardContent, priority, isFavorite, productId, featured } =
    props;

  const {
    gender,
    name,
    price,
    strikePrice,
    installments,
    discount,
    brandIcon,
    brandName
  } = cardContent;

  return (
    <ProductImageWrapper {...props}>
      <div className={styles.image}>
        {discount && (
          <div className={styles.discount}>
            <Text
              weight="bolder"
              textCase="uppercase"
              textColor="white"
              textSize="xs"
            >
              {`${discount}% off`}
            </Text>
          </div>
        )}
        {brandIcon && (
          <div className={styles.brand}>
            <Image
              src={brandIcon.url}
              alt={brandName}
              width={80}
              height={40}
              heightSkeleton="30px"
              quality={25}
            />
          </div>
        )}
        <div
          className={styles.favorite}
          id="favorite-btn"
        >
          <FavoriteButton
            isFavorite={isFavorite}
            productId={productId}
          />
        </div>
        <div className={styles.img}>
          <Image
            contain
            alt={name}
            src={imageSrc}
            priority={priority}
          />
        </div>
        {featured && (
          <div
            style={{
              background:
                !!featured?.background_color_left &&
                !!featured?.background_color_right
                  ? `linear-gradient(90deg, ${featured?.background_color_left}, ${featured?.background_color_right})`
                  : featured?.background_color,
              color: featured?.text_color
            }}
            className={styles.badge}
          >
            {featured?.name}
          </div>
        )}
      </div>
      <div className={styles.content}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
          <Text
            textCase="capitalize"
            textSize="xxs"
            textColor="var(--color-primary)"
          >
            {gender}
          </Text>
          <div>
            <Text
              textColor="var(--color-white-light)"
              variant="h3"
              textSize="m"
              className={styles.name}
              textStyle={{
                display: '-webkit-box',
                WebkitLineClamp: '2',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                wordBreak: 'unset'
              }}
            >
              {name}
            </Text>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
          <Text
            weight="bolder"
            variant="h4"
            textSize="l"
            textColor="var(--color-white-light)"
            textStyle={{
              inlineSize: 'max-content',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            {strikePrice ? (
              <strike style={{ fontSize: '.875rem', fontWeight: '400' }}>
                ${strikePrice.split(',')[0]}
              </strike>
            ) : (
              ''
            )}
            ${price.split(',')[0]}{' '}
          </Text>

          {installments && (
            <div className={styles.withoutInterestContainer}>
              <Text
                textColor="var(--color-primary)"
                variant="h4"
                textSize="xs"
                textStyle={{ whiteSpace: 'pre-wrap' }}
              >
                {installments}
              </Text>
            </div>
          )}
        </div>
      </div>
    </ProductImageWrapper>
  );
};

ProductImageWrapper.propTypes = {
  style: PropTypes.object.isRequired,
  productUrl: PropTypes.string,
  openModalOnClick: PropTypes.bool,
  children: PropTypes.node.isRequired
};
ProductImageWrapper.defaultProps = {
  productUrl: '',
  openModalOnClick: false
};

ProductGalleryCard.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  productUrl: PropTypes.string.isRequired,
  priority: PropTypes.bool,
  cardContent: PropTypes.shape({
    gender: PropTypes.oneOf([
      'mujer',
      'hombre',
      'niño',
      'unisex',
      'Mujer',
      'Hombre',
      'Niño',
      'Unisex'
    ]).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    strikePrice: PropTypes.string,
    installments: PropTypes.string,
    discount: PropTypes.number,
    brandIcon: PropTypes.object,
    brandName: PropTypes.string
  }).isRequired,
  style: PropTypes.object,
  isFavorite: PropTypes.bool,
  productId: PropTypes.number.isRequired,
  featured: PropTypes.object
};

ProductGalleryCard.defaultProps = {
  style: {},
  priority: false,
  isFavorite: false,
  featured: {}
};

export default ProductGalleryCard;
